const locationAwardTypes = ['StateRegion', 'Locality', 'Country', 'State', 'City', 'Neighbourhood', 'County'] as const;
export type LocationAwardType = (typeof locationAwardTypes)[number];

const leasingAwardType = ['LeasingCountry', 'LeasingState', 'LeasingCity', 'LeasingLocality'] as const;
export type LeasingAwardType = (typeof leasingAwardType)[number];

const specialAwardType = [
  'RisingStarFemale',
  'AgencyNetworkSmall',
  'AgencyNetworkMedium',
  'AgencyNetworkLarge',
  'RisingStarMale',
  'AgencyNetworkIndependent',
  'RisingStar',
] as const;

export type SpecialAwardType = (typeof specialAwardType)[number];

export const awardTypes = [...locationAwardTypes, ...leasingAwardType, ...specialAwardType, 'Unknown'] as const;

export type AwardType = (typeof awardTypes)[number];
