export * from './campaign-promoted-state.models';
export * from './campaign-result-methods.enum';
export * from './campaign-status.enum';
export * from './period-type.enum';

export const SizeUnit = {
  Square: 'Square',
  SquareMeter: 'SquareMeter',
  Acre: 'Acre',
  Hectare: 'Hectare',
  SquareFeet: 'SquareFeet',
} as const;

export type SizeUnit = (typeof SizeUnit)[keyof typeof SizeUnit];

export const ListingImageType = {
  FloorPlan: 'FloorPlan',
  Other: 'Other',
  Hero: 'Hero',
  CustomReviewImage: 'CustomReviewImage',
} as const;

export type ListingImageType = (typeof ListingImageType)[keyof typeof ListingImageType];
