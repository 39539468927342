export const VerticalType = {
  Sales: 'Sales',
  Leasing: 'Leasing',
  Finance: 'Finance',
} as const;

export type VerticalType = (typeof VerticalType)[keyof typeof VerticalType];

export function isVerticalType(value: string): value is VerticalType {
  return Object.values(VerticalType).includes(value as VerticalType);
}

export enum VerticalRouteType {
  Sales = 'sales',
  Finance = 'finance',
  Leasing = 'leasing',
}
