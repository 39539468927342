import { Injectable } from '@angular/core';
import { CurrentUser, CurrentUserService } from '@rma/accounts/user';
import { PlatformService } from '@rma/generic/util/environment';
import { LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { map } from 'rxjs/operators';
import { FeatureProviderService } from '../feat-feature-provider/feature-provider.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureUserService {
  constructor(
    private readonly featureProviderService: FeatureProviderService,
    private readonly currentUserService: CurrentUserService,
    private readonly platformService: PlatformService,
  ) {}

  initialise() {
    if (this.platformService.isPlatformServer) {
      return;
    }

    this.currentUserService.currentUser$
      .pipe(map((x) => (x && x.userType !== 'Consumer' ? x : null)))
      .subscribe((x) => (x ? this.identifyUser(x) : this.featureProviderService.unidentify()));
  }

  private identifyUser(user: CurrentUser) {
    const featureUser: LDSingleKindContext = {
      kind: 'user',
      key: user.userId,
      name: user.name,
      firstName: user.firstname,
      lastName: user.lastname,
      avatar: user.profileImage,
      custom: {
        agencies: user.agencies?.map((agency) => agency.name) as string[],
      },
    };
    this.featureProviderService.identify(featureUser);
  }
}
