import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment, PlatformService } from '@rma/generic/util/environment';
import { Observable } from 'rxjs';

@Injectable()
export class JsonHeaderInterceptor implements HttpInterceptor {
  private rmaApiUrls: string[];

  constructor(
    private readonly platformService: PlatformService,
    config: Environment,
  ) {
    // todo: add other frontend hittable api urls, reviews api, etc
    this.rmaApiUrls = [config.api.apiUrl, config.api.reviewsApiUrl, config.api.billingApiUrl];
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let headers = request.headers.set('rma.json', 'true').set('rma.tempjson', 'true');

    if (this.platformService.isPlatformServer) {
      headers = headers
        .set('x-internal', 'ServerSideRendering/1.0 (AWS Fargate) v31e46715-9d07-419c-9186-44cf9922743d')
        .set('User-Agent', 'ServerSideRendering/1.0 (AWS Fargate) v31e46715-9d07-419c-9186-44cf9922743d');
    }

    if (this.rmaApiUrls?.some((x) => request.url.startsWith(x))) {
      request = request.clone({
        headers,
      });
    }
    return next.handle(request);
  }
}
