// todo: DELETE
export enum ProfilePageTypes {
  RealEstateAgent = 'real-estate-agent',
  RealEstateAgency = 'real-estate-agency',
  MortgageBroker = 'mortgage-broker',
  MortgageBrokerTeam = 'mortgage-broker-team',
}

export enum AgentGroupingType {
  Agency = 'Agency',
  Office = 'Office',
  Team = 'Team',
  MortgageBrokerTeam = 'MortgageBrokerTeam',
}

export enum AgentType {
  RealEstate = 'RealEstate',
  MortgageBroker = 'MortgageBroker',
}
