import { Injectable } from '@angular/core';
import { safeJsonParse } from './utils';
import * as i0 from "@angular/core";
import * as i1 from "./cookies-options.service";
var CookiesService = /** @class */function () {
  function CookiesService(cookiesOptions) {
    this.options = cookiesOptions.options;
  }
  CookiesService.prototype.put = function (key, value, options) {
    this.cookiesWriter()(key, value, options);
  };
  CookiesService.prototype.putObject = function (key, value, options) {
    this.put(key, JSON.stringify(value), options);
  };
  CookiesService.prototype.get = function (key) {
    return this.cookiesReader()[key];
  };
  CookiesService.prototype.getObject = function (key) {
    var value = this.get(key);
    return value ? safeJsonParse(value) : value;
  };
  CookiesService.prototype.getAll = function () {
    return this.cookiesReader();
  };
  CookiesService.prototype.remove = function (key, options) {
    this.cookiesWriter()(key, undefined, options);
  };
  CookiesService.prototype.removeAll = function () {
    var _this = this;
    var cookies = this.getAll();
    Object.keys(cookies).forEach(function (key) {
      _this.remove(key);
    });
  };
  CookiesService.prototype.cookiesReader = function () {
    return {};
  };
  CookiesService.prototype.cookiesWriter = function () {
    return function () {};
  };
  CookiesService.ɵfac = function CookiesService_Factory(t) {
    return new (t || CookiesService)(i0.ɵɵinject(i1.CookiesOptionsService));
  };
  CookiesService.ɵprov = i0.ɵɵdefineInjectable({
    token: CookiesService,
    factory: CookiesService.ɵfac
  });
  return CookiesService;
}();
export { CookiesService };
/*@__PURE__*/
(function () {
  void 0;
})();
