import { ListingImageType } from './campaign';

export interface RmaImageSource {
  src: string;
  alt?: string;
  imageType?: ListingImageType;
}

export interface RmaSrcset {
  src: string;
  alt?: string;
  srcset: string;
  sizes: string;
}

export interface RmaSrcsetItem {
  optimisation: string;
  width: string;
}

export interface RmaSrcsetConfig {
  srcset: RmaSrcsetItem[];
  sizes: string;
}

export type FetchPriorityType = 'auto' | 'high' | 'low';

export const NO_PROPERTY_IMAGE = '/placeholder/no-property-placeholder.png';
