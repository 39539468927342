import { catchError, Observable, OperatorFunction, throwError } from 'rxjs';

/**
 * Catch an error and log for track js to consume
 * By default the error is re-thrown
 */
export function catchAndLogError<T>(caught?: Observable<T>): OperatorFunction<T, T> {
  return catchError((error) => {
    console.error(error);
    return caught ?? throwError(() => error);
  });
}
