import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';

const qsOptions: qs.IStringifyOptions = {
  indices: false,
  arrayFormat: 'indices',
  skipNulls: true,
  // having filter bypasses serialize date so have to serialize date here.
  filter: (_prefix, value) => (value === '' ? undefined : value instanceof Date ? value.toISOString() : value),
  serializeDate: (date: Date) => date.toISOString(),
};

export function httpParamMapper(params: unknown): HttpParams | undefined {
  if (!params) {
    return undefined;
  }

  const query = qs.stringify(params, qsOptions);
  return new HttpParams({ fromString: query });
}
