import { Inject, Injectable, InjectionToken } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { mergeOptions } from './utils';
import * as i0 from "@angular/core";
export var COOKIES_OPTIONS = new InjectionToken('COOKIES_OPTIONS');
var CookiesOptionsService = /** @class */function () {
  function CookiesOptionsService(options, injector) {
    if (options === void 0) {
      options = {};
    }
    this.injector = injector;
    this.defaultOptions = {
      path: this.injector.get(APP_BASE_HREF, '/'),
      domain: null,
      expires: null,
      secure: false
    };
    this._options = mergeOptions(this.defaultOptions, options);
  }
  Object.defineProperty(CookiesOptionsService.prototype, "options", {
    get: function () {
      return this._options;
    },
    enumerable: true,
    configurable: true
  });
  CookiesOptionsService.ɵfac = function CookiesOptionsService_Factory(t) {
    return new (t || CookiesOptionsService)(i0.ɵɵinject(COOKIES_OPTIONS), i0.ɵɵinject(i0.Injector));
  };
  CookiesOptionsService.ɵprov = i0.ɵɵdefineInjectable({
    token: CookiesOptionsService,
    factory: CookiesOptionsService.ɵfac
  });
  return CookiesOptionsService;
}();
export { CookiesOptionsService };
/*@__PURE__*/
(function () {
  void 0;
})();
