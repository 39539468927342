import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@rma/generic/util/environment';
import { Observable } from 'rxjs';
import { TokenProviderService } from '../util-token-provider';

@Injectable()
export class OldAuthInterceptor implements HttpInterceptor {
  private rmaApiUrls: string[];

  constructor(
    private readonly tokenProvider: TokenProviderService,
    config: Environment,
  ) {
    // todo: add other frontend hittable api urls, reviews api, etc
    this.rmaApiUrls = [config.api.apiUrl, config.api.reviewsApiUrl, config.api.billingApiUrl, config.api.surveyApiUrl];
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.rmaApiUrls?.some((x) => request.url.startsWith(x))) {
      const token = this.tokenProvider.getUserToken();

      if (token) {
        request = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${token}`),
          withCredentials: true,
        });
      }
    }
    return next.handle(request);
  }
}
