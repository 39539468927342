import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RmaUrlsService } from '@rma/generic/util/environment';
import { Observable } from 'rxjs';
import { CurrentUser } from './current-user.types';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private rmaUrl: RmaUrlsService,
  ) {}

  public getCurrentUser(): Observable<CurrentUser> {
    return this.httpClient.get<CurrentUser>(this.rmaUrl.apiUrl('v2/Users/Me'), { headers: { 'rma.json': 'true' } });
  }
}
