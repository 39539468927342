import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { CookiesService } from '@ngx-utils/cookies';
import { Environment } from '@rma/generic/util/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenProviderService {
  private userTokenKey = '';

  constructor(
    @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
    private readonly cookiesService: CookiesService,
    private readonly environment: Environment,
  ) {}

  public initialise() {
    this.userTokenKey = `${this.environment.identity.jwtEnvironment}-UserToken`;

    if (!this.hasUserToken()) {
      const cookieToken = this.cookiesService.get('jwt');
      if (cookieToken) {
        this.setUserToken(cookieToken);
      }
    }
  }

  public setUserToken(userToken: string): void {
    this.localStorage?.setItem(this.userTokenKey, userToken);
  }

  public getUserToken(): string | null {
    return this.localStorage?.getItem(this.userTokenKey);
  }

  public hasUserToken(): boolean {
    const token = this.getUserToken();
    return !!token && token !== 'null' && token !== 'undefined';
  }

  public clearUserToken(): void {
    this.localStorage.removeItem(this.userTokenKey);
  }
}
