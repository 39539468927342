import { Observable, OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Filter all `null`s and `undefined`s from the stream to make sure only values are emitted.
 */
export function nonNullable<T>(): OperatorFunction<T, NonNullable<T>> {
  return (source: Observable<T>): Observable<NonNullable<T>> =>
    source.pipe(filter((value: T): value is NonNullable<T> => value !== undefined && value !== null));
}
