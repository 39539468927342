// Not sure if these belong here
import { InjectionToken, StaticProvider } from '@angular/core';
import { Request, Response } from 'express';

export const REQUEST = new InjectionToken<Request>('REQUEST');
export const RESPONSE = new InjectionToken<Response>('RESPONSE');

export function provideExpressTokens(req: Request): StaticProvider[] {
  return [
    // The cookie service requires the string version :shrug:
    { provide: 'REQUEST', useValue: req },
    { provide: 'RESPONSE', useValue: req.res },
    { provide: REQUEST, useValue: req },
    { provide: RESPONSE, useValue: req.res },
  ];
}
