import { Observable, OperatorFunction, ReplaySubject, share, ShareConfig } from 'rxjs';

type RmaShareConfig<T> = Omit<ShareConfig<T>, 'connector'>;

const defaultConfig = { resetOnComplete: false, resetOnError: false, resetOnRefCountZero: true };

export function rmaShareReplay<T>(config: RmaShareConfig<T> = defaultConfig, replayCount = 1): OperatorFunction<T, T> {
  return (source: Observable<T>): Observable<T> =>
    source.pipe(
      share({
        connector: () => new ReplaySubject(replayCount),
        ...config,
      }),
    );
}
